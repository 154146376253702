<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 순회기간 -->
          <c-datepicker
            :range="true"
            label="LBL00001605"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 부서 -->
          <c-dept
            type="search"
            label="점검 주관부서"
            name="vendorCd"
            v-model="searchParam.vendorCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            :editable="false"
            codeGroupCd="PATROL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="patrolTypeCd"
            label="점검구분"
            v-model="searchParam.patrolTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card title="LBL00001634" class="cardClassDetailForm bodyOverflow-none">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <apexchart 
            ref="chart" 
            :height="chartHeight" 
            type="bar"
            :width="chart.chartWidth"
            :options="chart.chartOptions" 
            :series="chart.series"></apexchart>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'patrolGraphResult',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolTypeCd: '',
          title: '',
        }
      },
    },
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      chartHeight: 500,
      searchParam: {
        plantCd: null,
        vendorCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
        patrolTypeCd: null,
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      chart: {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }
            },
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          title: {
            text: ''
          },
          xaxis: {
            categories: [],
            labels: {
              formatter: function (val) {
                return val + "건"
              }
            }
          },
          yaxis: {
            title: {
              text: undefined
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + "건"
              }
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
        series: [
          {
            id: 'PRCC000001',
            name: '해당없음',
            data: [],
          },
          {
            id: 'PRCC000002',
            name: '양호',
            data: [],
          },
          {
            id: 'PRCC000003',
            name: '불량',
            data: [],
          },
          {
            id: 'PRCC000004',
            name: '미흡',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.graph.itemresult.url;
      this.searchParam.patrolTypeCd = this.patrolInfo.patrolTypeCd;
      // code setting
      this.searchParam.patrolYear = this.$comm.getThisYear();
      // list setting
      this.getList();
      this.setChartLabel();
      this.setSize();
    },
    setChartLabel() {
      this.chart.series = [
        {
          id: 'PRCC000001',
          name: this.$comm.getLangLabel('LBL00001630'), // 해당없음
          data: [],
        },
        {
          id: 'PRCC000002',
          name: this.$comm.getLangLabel('LBL00001631'), // 양호
          data: [],
        },
        {
          id: 'PRCC000003',
          name: this.$comm.getLangLabel('LBL00001632'), // 불량
          data: [],
        },
        {
          id: 'PRCC000004',
          name: this.$comm.getLangLabel('LBL00001633'), // 미흡
          data: [],
        },
      ]
      this.$refs['chart'].refresh();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.chart.chartOptions.xaxis.categories = [];
        let resultList1 = [];
        let resultList2 = [];
        let resultList3 = [];
        let resultList4 = [];
        this.$_.forEach(_result.data, item => {
          this.chart.chartOptions.xaxis.categories.push(item.patrolItemName)
          resultList1.push(item.cnt1)
          resultList2.push(item.cnt2)
          resultList3.push(item.cnt3)
          resultList4.push(item.cnt4)
        })

        this.$set(this.chart.series[0], 'data', resultList1)
        this.$set(this.chart.series[1], 'data', resultList2)
        this.$set(this.chart.series[2], 'data', resultList3)
        this.$set(this.chart.series[3], 'data', resultList4)
        this.$refs['chart'].refresh();
      },);
    },
    setSize() {
      if (this.patrolInfo.patrolTypeCd == 'PTC0000001') {
        let height = window.innerHeight - 340;
        height = height >= 400 ? height : 400
        this.chartHeight = height;
      } else {
        this.chartHeight = 1024;
      }
    },
  }
};
</script>
